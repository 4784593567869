import React, { useState } from "react";
import logo from "../assets/index2/images/logo.png";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "../assets/index2/css/responsive.css";
import "../assets/index2/css/animation.css";
import "../assets/index5/css/responsive.css";
import "../assets/index5/css/animation.css";
import { useNavigate } from 'react-router-dom';
import Video from "./Video";



function Home(){
    return (
     <div className="popreveal overflow-hidden">
      <div className="row">
        <Sidebar />
        <StepsCol />
      </div>
    </div>
    )
}

function Sidebar() {
    return (
      <div className="sidebar col-md-3 orders">
        <div className="sidebar-inner">
          <div className="logo">
            <div className="logo-icon">
              <img src={logo} alt="BeRifma" />
            </div>
            <div className="logo-text-home">
              Brainlox.ai<span>.</span>
            </div>
          </div>
          <div className="logo-footer-home">
            Beta
          </div>
        </div>
      </div>
    );
  }
  
  function StepsCol() {
    return (
      <div className="steps-col col-md-9">
        <div className="wrapper">
          <form id="steps" method="post" className="show-section">
            <Steps />
          </form>
        </div>
      </div>
    );
  }
  
  function Steps() {
    const navigate= useNavigate();
    const [persona,setPersona]=useState(0)
    const [reason,setReason]=useState('')
    function handleReasonChange(e){
      setReason(e.target.value)
    }
    function handleChange(){
        navigate("/Video")
    }
    return (
      <section className="steps">
        <div className="step-inner">
          <div className="main-heading">
            Why do you want to learn programming language?
          </div>
          <div class="mb-3">
            <textarea className="form-control" rows="3" onChange={handleReasonChange} value={reason}></textarea>
          </div>
  
          {/* Step 6 Form */}
          <div id="step2" className="borderc">
          <div className="main-heading">
            Choose an Avatar
          </div>
            <div className="row">
              <div className="col-md-6 lap-50">
                {/* <a href="./batman_video_print.html"> */}
                  <div className={`radio-field-2 ${persona==0 && 'select'}`} onClick={()=>{
                    setPersona(0)
                    localStorage.setItem('persona',0)
                    }}>
                    <div className="radio-img">
                      <img src="https://res.cloudinary.com/triluxo-technologies-private-limited/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1679336485/brainlox_ai/tutor_character/batman_mfrb1g.png" alt="" />
                    </div>
                    <label>Batman</label>
                  </div>
                {/* </a> */}
              </div>
              <div className="col-md-6 lap-50">
                {/* <a href="./captain_video_print.html"> */}
                  <div className={`radio-field-2 delay-200ms ${persona==1 && 'select'}`} onClick={()=>{
                    setPersona(1)
                    localStorage.setItem('persona',1)
                  }}>
                    <div className="radio-img">
                      <img src="https://res.cloudinary.com/triluxo-technologies-private-limited/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1679336485/brainlox_ai/tutor_character/captain_america_q7ql8a.png" alt="" />
                    </div>
                    <label>Captain America</label>
                  </div>
                {/* </a> */}
              </div>
              <div className="col-md-6 lap-50">
                {/* <a href="./griffin_video_print.html"> */}
                  <div className={`radio-field-2 delay-200ms ${persona==2 && 'select'}`} onClick={()=>{
                    setPersona(2)
                    localStorage.setItem('persona',2)
                  }}>
                    <div className="radio-img">
                      <img src="https://res.cloudinary.com/triluxo-technologies-private-limited/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1679336485/brainlox_ai/tutor_character/peter_griffin_t03x9v.webp" alt="" />
                    </div>
                    <label>Peter Griffin</label>
                  </div>
                {/* </a> */}
              </div>
              <div className="col-md-6 lap-50">
                {/* <a href="./sponge_video_print.html"> */}
                  <div className={`radio-field-2 delay-200ms ${persona==3 && 'select'}`} onClick={()=>{
                    setPersona(3)
                    localStorage.setItem('persona',3)
                  }}>
                    <div className="radio-img">
                      <img src="https://res.cloudinary.com/triluxo-technologies-private-limited/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1679336485/brainlox_ai/tutor_character/Sponge_BOB_sh6hz2.jpg" alt="" />
                    </div>
                    <label>Spongebob Squarepants</label>
                  </div>
                {/* </a> */}
              </div>
            </div>
          </div>
          <div className="next-prev">
            <button className='stepbtn coloredButton' type="button" onClick={handleChange}>
              <span>Next</span>
            </button>
          </div>
        </div>
      </section>
    );
  }

  export default Home;