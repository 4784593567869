const data=[
        [
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/batman+-+print+-+function.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/baitman+for+loop+function.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/baitman+if+else+statements.mp4"
        ],
        [
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captain+america+-+print+function.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captai+america+-+for+loop+function.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captain+america+-+if+else+statement.mp4"

        ],
        [
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/print.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/for.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/if.mp4"
        ],
        [
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/print.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/for.mp4",
                "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/if.mp4"
        ]
]
const data1=[
        {
                printVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/batman+-+print+-+function.mp4",
                loopVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/baitman+for+loop+function.mp4",
                ifelseVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Batman/baitman+if+else+statements.mp4"
        },
        {
               printVideo: "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captain+america+-+print+function.mp4",
                loopVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captai+america+-+for+loop+function.mp4",
                ifelseVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Captain+america/captain+america+-+if+else+statement.mp4"
        },
        {       
               printVideo: "https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/print.mp4",
                 loopVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/for.mp4",
                ifelseVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Peter+Griffin/if.mp4"
        },
        {
                printVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/print.mp4",
                loopVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/for.mp4",
                ifelseVideo:"https://tutorial-videos-brainlox.s3.ap-south-1.amazonaws.com/Sponge+BOB/if.mp4"   
        }
]
export default data;