import React from "react";
import logo from "./assets/index2/images/logo.png";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./assets/index2/css/style.css";
import "./assets/index2/css/responsive.css";
import "./assets/index2/css/animation.css";
import "./assets/index5/css/style.css";
import "./assets/index5/css/responsive.css";
import "./assets/index5/css/animation.css";
import Video from "./components/Video";
import Home from "./components/Home";

function App() {

  return (
    <div>
      <BrowserRouter>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/video" element={<Video/>}/>
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  )


  // return (
  //   <div className="popreveal overflow-hidden">
  //     <div className="row">
  //       <Sidebar />
  //       <StepsCol />
  //       {/* <Batman/> */}
  //       {/* <CaptainAmerica/> */}
  //     </div>
  //   </div>
  // );
}

function Sidebar() {
  return (
    <div className="sidebar col-md-3 orders">
      <div className="sidebar-inner">
        <div className="logo">
          <div className="logo-icon">
            <img src={logo} alt="BeRifma" />
          </div>
          <div className="logo-text">
            Brainlox.ai<span>.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function StepsCol() {
  return (
    <div className="steps-col col-md-9">
      <div className="wrapper">
        <form id="steps" method="post" className="show-section">
          <Steps />
        </form>
      </div>
    </div>
  );
}

function Steps() {
  return (
    <section className="steps">
      <div className="step-inner">
        <div className="main-heading">
          Select the persona from whom you want to learn from.
        </div>

        {/* Step 6 Form */}
        <div id="step2" className="borderc">
          <div className="row">
            <div className="col-md-6 lap-50">
              {/* <a href="./batman_video_print.html"> */}
                <div className="radio-field-2" >
                  <div className="radio-img">
                    <img src="https://icon-library.com/images/icon-batman/icon-batman-8.jpg" alt="" />
                  </div>
                  <label>Batman</label>
                </div>
              {/* </a> */}
            </div>
            <div className="col-md-6 lap-50">
              {/* <a href="./captain_video_print.html"> */}
                <div className="radio-field-2 delay-100ms">
                  <div className="radio-img">
                    <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/39af738b-9c56-4e10-92a5-0803596e9df5/dcvmr5k-17bd749d-829d-43a9-ba69-fa4f62d95fe1.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzM5YWY3MzhiLTljNTYtNGUxMC05MmE1LTA4MDM1OTZlOWRmNVwvZGN2bXI1ay0xN2JkNzQ5ZC04MjlkLTQzYTktYmE2OS1mYTRmNjJkOTVmZTEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.Oa7w0Q_wAo07UiuPAwJ5EEqWGidYvxtaV3a96NJih58" alt="" />
                  </div>
                  <label>Captain America</label>
                </div>
              {/* </a> */}
            </div>
            <div className="col-md-6 lap-50">
              {/* <a href="./griffin_video_print.html"> */}
                <div className="radio-field-2 delay-200ms">
                  <div className="radio-img">
                    <img src="https://mcdn.wallpapersafari.com/medium/9/1/u7tFph.jpg" alt="" />
                  </div>
                  <label>Peter Griffin</label>
                </div>
              {/* </a> */}
            </div>
            <div className="col-md-6 lap-50">
              {/* <a href="./sponge_video_print.html"> */}
                <div className="radio-field-2 delay-200ms">
                  <div className="radio-img">
                    <img src="https://images5.alphacoders.com/373/373563.jpg" alt="" />
                  </div>
                  <label>Spongebob Squarepants</label>
                </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
