import { useEffect, useState } from 'react';
import logo from "../assets/index2/images/logo.png";
import '../css/bootstrap.min.css';
import data from './video_data';
import {FaDiscord} from 'react-icons/fa'
import {MdFeedback} from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
} from "react-share"

function Video() {
  const persona=localStorage.getItem('persona')
  const character=[
    'Batman',
    'Captain America',
    'Peter Griffin',
    'Sponge BoB'
  ]
  const [progress, setProgress] = useState(0);
  const [index,setIndex]=useState(0)
  const [video,setVideo]=useState(data[persona][index])
  const [show, setShow] = useState(false);
  const [mail,setMail]=useState('')
  const [feedback,setFeedback]=useState('')
  const [title,setTitle]=useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange=()=>{
    if(index>=2){
      setShow(true)
    }
    else{
      setIndex(prevIndex=>{
        return prevIndex+1
      })
    }
  }
  useEffect(()=>{
    setVideo(data[persona][index])
    if (index==0){
      setTitle('how to print a message on screen')
    }
    else if(index==1) {setTitle('looping')}
    else if(index ==2) {setTitle("if else conditioning")}
  },[index])

  const handleNextClick = () => {
    setProgress(progress + 25);
  };
 const handleEmailChange=(e)=>{
  setMail(e.target.value)
 }
 const handleFeedbackChange=(e)=>{
  setFeedback(e.target.value)
 }
 const handleSubmit=(e)=>{
  e.preventDefault();
  const url = 'https://wt0ujp0ny4.execute-api.us-east-1.amazonaws.com/prod/usr-data'
  const requestOptions = {
      method: 'POST',
      body: `{\"email\":\"${mail}",\"reason\":\"lol\",\"feedback":\"${feedback}\"}`
  };
  fetch(url, requestOptions)
  setShow(false)

 }

  return (
    <main className="main">
      <div className="main-wrapper">
        <div className="main-inner">

          {/* logo */}
          <div className="header">

          <div className="logo">
            <div className="logo-icon">
              <img src={logo} alt="" />
            </div>
            <div className="logo-text">
              Brainlox.ai
            </div>
            <br/>
            <div className="logo-footer">Beta</div>
          </div>
          <div class="dropdown">
            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Avatar
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">{character[persona]}</a></li>
              {
                character.map((value,index)=>{
                  if(index != persona){
                    return(
                      <li><a class="dropdown-item" href="#">{value}</a></li>
                    )
                  }
                })
              }
            </ul>
          </div>
          </div>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="emailAddress" className="form-label">Email address</label>
              <input type="email" className="form-control" id="emailAddress" aria-describedby="emailHelp" name='email' onChange={handleEmailChange} value={mail}/>
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="mb-3">
              <textarea className="form-control"  rows="3" name='feedback' onChange={handleFeedbackChange} value={feedback}></textarea>
          </div>
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
          </form>
        </Modal.Body>
      </Modal>

          {/* form */}
          <form id="steps" method="post" encType="multipart/form-data">
            <div className="show-section">

              {/* step 1 */}
              <section className="steps step1" id="step1">

                <div className="container">
                  <article className="text-content">
                    {/* heading */}
                    <h2 className="main-heading">
                      Story time!
                    </h2>
                  </article>

                  {/* step 1 form */}
                  <div className="steps-inner">
                    <article className="text-content mt-5">
                      <p className="main-text">
                        Let's learn the story about {title}.
                      </p>
                    </article>
                    <br />
                    <div>
                      <iframe src={video}
                        id="topic-1-video"
                        width="100%"
                        height="500px"
                        title="video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="lightSpeedIn row">
                      <div className="tab-50 col-md-12">
                        <p id="topic-1-description">

                        </p>
                      </div>
                    </div>

                    {/* step 1 button */}
                    <div className="next-prev">
                      <a href="../topic2/topic-intro.html">
                        {/* <button className="btn btn-primary next" id="step1btn" type="button" onClick={handleNextClick}>Next<FontAwesomeIcon icon={faArrowRight} /></button> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="step1-img">
                  <img src="./assets/index5/images/step1-img-1.png" alt="" />
                </div>
                <div className="step1-img-2">
                  <img src="./assets/index5/images/step1-img-2.png" alt="" />
                </div>
                <div className="progress-counter">
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                  </div>
                </div>
              </section>

            </div>
          </form>
          <div className="next-prev">
            <div className="stepbtn discord-note">
              <a target='_blank' href='https://discord.gg/Y6EukMUD' style={{color:'black'}}>
                <FaDiscord className='icons' />
              </a>
              <MdFeedback className='icons'onClick={handleShow}/>
            </div>
            <button className='next stepbtn coloredButton' type="button" onClick={handleChange}>
              <span>Next</span>
            </button>
            <div className="share-btn">
              <FacebookShareButton quote={title} hashtag='#brainlox.ai' children={'Video'} url={'https://brainlox.ai/'}>
                <FacebookIcon size={32} round={true} className='icons'/>
              </FacebookShareButton>
              <TwitterShareButton title={title} hashtags={['brainlox.ai']} children={'Video'} url={'https://brainlox.ai/'}>
                <TwitterIcon size={32} round={true}/>
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default Video;